/* eslint-disable @wordpress/dependency-group */
// import ScrollReveal from 'scrollreveal';
import inView from 'in-view';

(function(document, $) {
	const items = [...document.querySelectorAll('.timeline-item')],
		// root = document.documentElement,
		// itemsLength = items.length,
		slider = $('[rel="timeline-slider"]'),
		slickSettings = {
			mobileFirst: true,
			slidesToShow: 1,
			arrows: false,
			dots: true,
			autoplay: true,
		};

	// const slideRight = {
	// 	delay: 2000,
	// 	distance: '50px',
	// 	duration: 1000,
	// 	origin: 'right',
	// 	opacity: null,
	// 	useDelay: 'onload',
	// 	reset: true,
	// 	mobile: false,
	// };

	// const slideUp = {
	// 	distance: '200px',
	// 	origin: 'bottom',
	// 	// delay: 1000,
	// 	duration: 1500,
	// 	// useDelay: 'onload',
	// 	opacity: null,
	// 	interval: 600,
	// };

	// root.style.setProperty('--number-of-rows', itemsLength);

	items.forEach((item, index) => {
		const openButton = item.querySelector('[rel="show-details"]'),
			moreTextBox = item.querySelector('[rel="timeline-more"]');
		if (openButton) {
			openButton.addEventListener('click', () => {
				// console.log('event', event.target);
				moreTextBox.parentElement.classList.toggle('more-box-is-open');
				// moreTextBox.scrollIntoView(false, 'smooth');
				// moreTextBox.classList.toggle('more-box-content-open');
				$(moreTextBox).slideToggle();
			});
		}

		// item.style.setProperty('--row-position-start', index + 1);
		// item.style.setProperty('--row-position-end', index + 3);

		// if (
		// 	item.classList.contains('has-no-details') &&
		// 	item.classList.contains('has-no-image')
		// ) {
		// 	item.style.setProperty('--row-position-end', index + 2);
		// } else {
		// 	item.style.setProperty('--row-position-end', index + 3);
		// }
		// item.style.setProperty('--row-position-end', index + 2);

		// ScrollReveal().reveal(item, slideUp);
	});
	slider.slick(slickSettings);
	inView('.timeline-item').on('enter', (el) => {
		el.classList.add('in-view');
	});

})(document, jQuery);
